//JQUERY SHARRE
$('.sharre-facebook').sharrre({
    share: {
        facebook: true
    },
    enableHover: false,
    enableTracking: true,
    template: '<a class="box" href="#"><div class="share"><span></span></div></a>',
    click: function (api, options) {
        api.simulateClick();
        api.openPopup('facebook');
    }
});

$('.sharre-twitter').sharrre({
    share: {
        facebook: true
    },
    enableHover: false,
    enableTracking: true,
    template: '<a class="box" href="#"><div class="share"><span></span></div></a>',
    click: function (api, options) {
        api.simulateClick();
        api.openPopup('twitter');
    }
});

$('.sharre-google-plus').sharrre({
    share: {
        facebook: true
    },
    enableHover: false,
    enableTracking: true,
    template: '<a class="box" href="#"><div class="share"><span></span></div></a>',
    click: function (api, options) {
        api.simulateClick();
        api.openPopup('googlePlus');
    }
});